export enum RO_STATES {
  // orçamento
  budget = 2,
  authorization = 4,
  appointment = 5,
  
  // OS
  readyToStart = 6, // agendada no APP
  started = 7, // os aberta
  toReceive = 8, // os fechada
  evaluation = 9,
  finished = 10, // os faturada

  rejected = 11, // orçamento rejeitado
  all = 12 // local only
}

// export const roStates = {
//   all: {
//     id: 12,
//     label: 'ELEMENT.RO_STATE.ALL',
//     name: 'all',
//     mechanic: true
//   },
//   budget: {
//     id: 2,
//     label: 'ELEMENT.RO_STATE.BUDGET',
//     name: 'budget',
//     mechanic: false
//   },
//   authorization: {
//     id: 4,
//     label: 'ELEMENT.RO_STATE.AUTHORIZATION',
//     name: 'authorization',
//     mechanic: false
//   },
//   appointment: {
//     id: 5,
//     label: 'ELEMENT.RO_STATE.APPOINTMENT',
//     name: 'appointment',
//     mechanic: false
//   },
//   // readyToStart: {
//   //   id: 6,
//   //   label: 'ELEMENT.RO_STATE.READY_TO_START',
//   //   name: 'readyToStart',
//   //   mechanic: true
//   // },
//   started: {
//     id: 7,
//     label: 'ELEMENT.RO_STATE.STARTED',
//     name: 'started',
//     mechanic: true
//   },
//   toReceive: {
//     id: 8,
//     label: 'ELEMENT.RO_STATE.TO_RECEIVE',
//     name: 'toReceive',
//     mechanic: false
//   },
//   evaluation: {
//     id: 9,
//     label: 'ELEMENT.RO_STATE.EVALUATION',
//     name: 'evaluation',
//     mechanic: false
//   },
//   finished: {
//     id: 10,
//     label: 'ELEMENT.RO_STATE.FINISHED',
//     name: 'finished',
//     mechanic: false
//   },
//   rejected: {
//     id: 11,
//     label: 'ELEMENT.RO_STATE.REJECTED',
//     name: 'rejected',
//     mechanic: false
//   }
// }
